import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
} from 'mw-style-react';
import {injectIntl} from 'react-intl';
import ProductSlider from './components/Slider';
import Slider from '../../components/Slider';
import './Product.scss';
import ProductItem from './components/ProductItem';
import Demo from '../../components/Demo/Demo';
import { Link } from '../../i18n';
import Benefits from '../../components/Benefits/Benefits';
import Roadmap from '../../components/Roadmap/Roadmap';
import Image from '../../components/Image/Image';

const Product = (props) => {
  const {intl, data} = props;
  const {header, process, assemble, organize, share, control, additions, analize} = data;
  const fistTypeSlider = 'horizontal';

  // if (typeof window !== 'undefined') {
  //   if (window.innerWidth < 767) {
  //     fistTypeSlider = 'horizontal';
    // }
  // }

  return (
    <section className="product">
      <div className="product__header" />
      <div className="product__fff">
        <div className="product__wrap">
          <div
            className="product__title"
          >
            <h1>{intl.formatMessage({id: header.title})}</h1>
          </div>
          <Label
            value={intl.formatMessage({id: header.subTitle})}
            className="product__subTitle"
          />
          <ProductSlider
            status={'managers'}
            data={assemble}
            type={fistTypeSlider}
          />
        </div>
      </div>
      <div className="product__garey">
        <div className="product__wrap">
          <div className="grid-wrapper">
            {process.list.map((item, index) => (
              <div className={`col-${12 / additions.list.length}`} key={index}>
                <ProductItem item={item}/>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="product__fff">
        <div className="product__wrap">
          <ProductSlider
            status={'managers'}
            data={organize}
            bg={false}
            type="horizontal"
          />
        </div>
      </div>
      <div className="product__garey">
        <div className="product__wrap">
          <div className="grid-wrapper">
            <div className="col-6 product__part product__part__share">
              <Label value={intl.formatMessage({id: share.title})} className="product__part__title"/>
              <Label value={intl.formatMessage({id: share.subTitle})} className="product__part__subTitle"/>
              {share.list.map((item, index) => (
                <div className="product__part__column" key={index}>
                  <ProductItem item={item}/>
                </div>
              ))}
            </div>
            <div className="col-6 product__part product__part__control">
              <Label value={intl.formatMessage({id: control.title})} className="product__part__title"/>
              <Label value={intl.formatMessage({id: control.subTitle})} className="product__part__subTitle"/>
              {control.list.map((item, index) => (
                <div className="product__part__column" key={index}>
                  <ProductItem item={item}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="product__fff">
        <div className="product__wrap">
          <div className="grid-wrapper">
            {additions.list.map((item, index) => (
              <div className={`col-${12 / additions.list.length}`} key={index}>
                <ProductItem item={item}/>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="product__garey">
        <div className="product__wrap">
          <ProductSlider
            status={'managers'}
            data={analize}
            bg={false}
            type="horizontal"
          />
        </div>
      </div>
      {/* <Roadmap/>*/}
      <Slider background={'#fff'}/>
      <div
        className="product__benefits"
      >
        <Benefits />
      </div>
      <Demo/>
    </section>
  );
};

Product.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(Product);
