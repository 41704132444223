import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  Button,
} from 'mw-style-react';
import cn from 'classnames';
import './Benefits.scss';
import BenefitsList from './components/BenefitsList';
// import Image from '../Image/Image';

class Benefits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 'managers',
    };
  }

  changeStatus(status) {
    this.setState({
      status,
    });
  }

  render() {
    const { intl } = this.props;
    const { status } = this.state;

    return (
      <div className="benefits">
        <div className="benefits__header">
          <h1 className="benefits__header__title">
            {intl.formatMessage({ id: 'benefitsTitle' })}
          </h1>
          <h2 className="benefits__header__subTitle">
            {intl.formatMessage({ id: 'benefitsSubTitle' })}
          </h2>
          <div className="benefits__header__buttons">
            <Button
              type="text"
              size="large"
              label={intl.formatMessage({ id: 'benefitsManagers' })}
              className={cn('benefits__header__button managers', { active: status === 'managers' })}
              onClick={() => this.changeStatus('managers')}
            />
            <Button
              type="text"
              size="large"
              label={intl.formatMessage({ id: 'benefitsDevelopers' })}
              className={cn('benefits__header__button developers', { active: status === 'developers' })}
              onClick={() => this.changeStatus('developers')}
            />
          </div>
        </div>

        <div className="benefits__content">
          <BenefitsList
            status={status}
            intl={intl}
          />
        </div>
      </div>
    );
  }
}

Benefits.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Benefits);

