import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import ProductPage from '../templates/Product';

import editFinal from '../videos/Edit.mp4';
import debugFinal from '../videos/Debug.mp4';
import viewFinal from '../videos/View.mp4';

const product = {
  header: {
    title: 'productHeaderTitle',
    subTitle: 'productHeaderSubTitle'
  },
  assemble: {
    title: 'productAssembleTitle',
    subTitle: 'productAssembleSubTitle',
    list: [
      {id: 1, title: 'productAssembleListEditTitle', icon: 'pan.png', video: editFinal, text: 'productAssembleListEditText'},
      {id: 2, title: 'productAssembleListViewTitle', icon: 'eyes.png', video: viewFinal, text: 'productAssembleListViewText'},
      {id: 3, title: 'productAssembleListDebugTitle', icon: 'beetle.png', video: debugFinal, text: 'productAssembleListDebugText'},
    ],
    cons: []
  },
  process: {
    list: [
      {title: 'productAssembleConsHostTitle', icon: 'box.png', link: '/new-pricing', text: 'productAssembleConsHostText', event: 'see_all_hosting_options'},
      {title: 'productAssembleConsExecutionTitle', icon: 'rocket.png', text: 'productAssembleConsExecutionText'}
    ]
  },
  organize: {
    title: 'productOrganizeTitle',
    subTitle: 'productOrganizeSubTitle',
    list: [
      {id: 1, title: 'productOrganizeListProjectsTitle', icon: 'boxs.png', image: 'product/projects.png', text: 'productOrganizeListProjectsText'},
      {id: 2, title: 'productOrganizeListComponentsTitle', icon: 'file.png', image: 'product/components.png', text: 'productOrganizeListComponentsText'},
      {id: 3, title: 'productOrganizeListProcessesTitle', icon: 'setting.png', image: 'product/processes.png', text: 'productOrganizeListProcessesText'},
    ],
    cons: []
  },
  share: {
    title: 'productShareTitle',
    subTitle: 'productShareSubTitle',
    list: [
      {title: 'productShareListUsersTitle', icon: 'user.png', text: 'productShareListUsersText'},
      {title: 'productShareListGroupsTitle', icon: 'users.png', text: 'productShareListGroupsText'},
      {title: 'productShareListApiTitle', icon: 'rosette.png', text: 'productShareListApiText'},
    ]
  },
  control: {
    title: 'productControlTitle',
    subTitle: 'productControlSubTitle',
    list: [
      {title: 'productControlListViewTitle', icon: 'eys.png', text: 'productControlListViewText'},
      {title: 'productControlListModifyTitle', icon: 'key.png', text: 'productControlListModifyText'},
      {title: 'productControlListTaskTitle', icon: 'later.png', text: 'productControlListTaskText'},
    ]
  },
  additions: {
    list: [
      {title: 'productAdditionsListCollaborationTitle', icon: 'hands.png', text: 'productAdditionsListCollaborationText'},
      {title: 'productAdditionsListTransparencyTitle', icon: 'eyes.png', text: 'productAdditionsListTransparencyText'}
    ]
  },
  analize: {
    title: 'productAnalizeTitle',
    subTitle: 'productAnalizeSubTitle',
    list: [
      {id: 1, title: 'productAnalizeListCustomizableTitle', icon: 'dashbord.png', image: 'product/customizable-dashboards.png', text: 'productAnalizeListCustomizableText'},
      {id: 2, title: 'productAnalizeListActivityTitle', icon: 'graf.png', image: 'product/activity-monitoring.png', text: 'productAnalizeListActivityText'},
    ]
  },
  demo: {
    title: 'productDemoTitle',
    button: {
      text: 'productDemoButton',
    }
  }
};

const Product = (props) => (
  <Layout {...props} page={'product'}>
    <ProductPage
      data={product}
    />
  </Layout>
);


export default withIntl(Product);
