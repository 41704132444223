import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import './Slider.scss';
import ProductItem from '../ProductItem';
import Image from '../../../../components/Image/Image';

class Slider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSlideId: props.data.list[0].id,
      lastSlideId: props.data.list[0].id,
      clearView: false
    };
  }

  renderList(data, activeSlideId, isHorizontal) {
    const {intl} = this.props;
    const col = isHorizontal ? `col-${12 / data.length}` : '';
    return data.map(item => (
      <div
        key={item.id}
        className={cn(`product__slider__item ${col}`, {
          active: activeSlideId === item.id,
        })}
        onClick={() => this.changeSlide(item.id)}
      >
        <Image file={item.icon} className="product__slider__item__icon"/>
        <div className="product__slider__item__content">
          <div className="product__slider__item__text">{intl.formatMessage({id: item.title})}</div>
          <div className="product__slider__item__subText">{intl.formatMessage({id: item.text})}</div>
        </div>
      </div>
    ));
  }

  changeSlide(id) {
    this.setState({
      activeSlideId: id
    });
  }

  renderSlide(list, lastSlideId, activeSlideId, clearView) {
    const active = list.find(item => item.id === activeSlideId);
    const last = list.find(item => item.id === lastSlideId);
    if (!active) return;

    if (lastSlideId !== activeSlideId) {
      setTimeout(() => {
        this.setState({
          lastSlideId: activeSlideId,
          clearView: true
        });
      }, 200);
      return (
        <div className="product__slider__contentWrap">
          {last.image ?
            <Image file={last.image} className="product__slider__content"/> :
            <video className="product__slider__content" autoPlay loop>
              <source src={active.video} type="video/mp4" />
            </video>
          }
        </div>
      );
    }

    if (clearView) {
      this.setState({
        clearView: false
      });
      return <div className="product__slider__contentWrap active" />;
    }

    return (
      <div className="product__slider__contentWrap active">
        {active.image ?
          <Image file={active.image} className="product__slider__content"/> :
          <video className="product__slider__content" autoPlay loop>
            <source src={active.video} type="video/mp4" />
          </video>
        }
      </div>
    );
  }

  renderCons(cons) {
    return cons.map((c, index) => (
      <div className="col-6 product__slider__cons__item" key={index}>
        <ProductItem item={c} />
      </div>
    ));
  }

  render() {
    const { data, type, intl } = this.props;
    const { activeSlideId, lastSlideId, clearView } = this.state;
    if (type === 'horizontal') {
      return (
        <div>
          <div className="product__slider product__slider__horizontal">
            <div className="product__slider__list__title">{intl.formatMessage({id: data.title})}</div>
            <div className="product__slider__list__subTitle">{intl.formatMessage({id: data.subTitle})}</div>
            {this.renderSlide(data.list, lastSlideId, activeSlideId, clearView)}
            <div className="grid-wrapper product__slider__listH">
              {this.renderList(data.list, activeSlideId, true)}
            </div>
          </div>
          {data.cons &&
            <div className="grid-wrapper product__slider__cons">
              {this.renderCons(data.cons)}
            </div>
          }
        </div>
      );
    }

    return (
      <div>
        <div className="grid-wrapper product__slider">
          <div className="product__slider__list col-4">
            <div className="product__slider__list__title">{intl.formatMessage({id: data.title})}</div>
            <div className="product__slider__list__subTitle">{intl.formatMessage({id: data.subTitle})}</div>
            {this.renderList(data.list, activeSlideId)}
          </div>
          <div className="product__slider__item__wrapper col-8">
            {this.renderSlide(data.list, lastSlideId, activeSlideId, clearView)}
          </div>
        </div>
        <div className="grid-wrapper product__slider__cons">
          {this.renderCons(data.cons)}
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
  type: PropTypes.string
};

export default injectIntl(Slider);
