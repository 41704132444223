import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// import { Link } from 'gatsby';
import './ProductItem.scss';
// import { Link } from '../../../../i18n';
import Utils from '../../../../utils/utils';
import Image from '../../../../components/Image/Image';

class ProductItem extends PureComponent {
  render() {
    const { item, intl } = this.props;

    return (
      <div className="product__item">
        <Image file={item.icon} className="product__item__icon"/>
        <div className="product__item__content">
          <div className="product__item__text">{intl.formatMessage({id: item.title})}</div>
          <div className="product__item__subText">
            <span
              onClick={() => Utils.sendEvent(item.event)}
              dangerouslySetInnerHTML={{
                __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.text}))
              }}
            />
            {/* {item.link &&
              <Link
                className="product__item__link"
                to={item.link}
              >
                {intl.formatMessage({id: 'here'})}.
              </Link>
            }*/}
          </div>
        </div>
      </div>
    );
  }
}

ProductItem.propTypes = {
  item: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(ProductItem);
