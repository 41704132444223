import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './BenefitsList.scss';
import Image from '../../../Image/Image';

const slider = {
  managers: [
    {
      title: 'benefitsManagersSpeed',
      icon: 'clock.png',
      text: 'benefitsManagersSpeedText'
    },
    {
      title: 'benefitsManagersDashboards',
      icon: 'graf.png',
      text: 'benefitsManagersDashboardsText'
    },
    {
      title: 'benefitsManagersEsc',
      icon: 'ring2.png',
      text: 'benefitsManagersEscText'
    },
    {
      title: 'benefitsManagersCurve',
      icon: 'cap.png',
      text: 'benefitsManagersCurveText'
    },
    {
      title: 'benefitsManagersProto',
      icon: 'pan.png',
      text: 'benefitsManagersProtoText',
    },
    {
      title: 'benefitsManagersBusiness',
      icon: 'setting.png',
      text: 'benefitsManagersBusinessText',
    },
    {
      title: 'benefitsManagersEngagement',
      icon: 'lamp.png',
      text: 'benefitsManagersEngagementText',
    },
    {
      title: 'benefitsManagersCollaboration',
      icon: 'hands.png',
      text: 'benefitsManagersCollaborationText',
    },
    {
      title: 'benefitsManagersTransparency',
      icon: 'eyes.png',
      text: 'benefitsManagersTransparencyText',
    },
    {
      title: 'benefitsManagersSecurity',
      icon: 'lock.png',
      text: 'benefitsManagersSecurityText',
    },
    {
      title: 'benefitsManagersHigh',
      icon: 'dashbord.png',
      text: 'benefitsManagersHighText',
    },
    {
      title: 'benefitsManagersUltra',
      icon: 'balance.png',
      text: 'benefitsManagersUltraText',
    }
  ],
  developers: [
    {
      title: 'benefitsDevelopersProcess',
      icon: 'instruments.png',
      text: 'benefitsDevelopersProcessText',
    },
    {
      title: 'benefitsDevelopersHosting',
      icon: 'key.png',
      text: 'benefitsDevelopersHostingText',
    },
    {
      title: 'benefitsDevelopersDebug',
      icon: 'ring2.png',
      text: 'benefitsDevelopersDebugText',
    },
    {
      title: 'benefitsDevelopersDashboards',
      icon: 'graf.png',
      text: 'benefitsDevelopersDashboardsText',
    },
    {
      title: 'benefitsDevelopersEscalations',
      icon: 'ring2.png',
      text: 'benefitsDevelopersEscalationsText',
    },
    {
      title: 'benefitsDevelopersPopular',
      icon: 'chain.png',
      text: 'benefitsDevelopersPopularText',
    },
    {
      title: 'benefitsDevelopersApi',
      icon: 'rosette.png',
      text: 'benefitsDevelopersApiText',
    },
    {
      title: 'benefitsDevelopersFrameworks',
      icon: 'file.png',
      text: 'benefitsDevelopersFrameworksText',
    },
    {
      title: 'benefitsDevelopersErlang',
      icon: 'tv.png',
      text: 'benefitsDevelopersErlangText',
    },
    {
      title: 'benefitsDevelopersEvent',
      icon: 'setting.png',
      text: 'benefitsDevelopersEventText',
    },
    {
      title: 'benefitsDevelopersParallel',
      icon: 'users.png',
      text: 'benefitsDevelopersParallelText',
    },
    {
      title: 'benefitsDevelopersManagement',
      icon: 'key2.png',
      text: 'benefitsDevelopersManagementText',
    },
    {
      title: 'benefitsDevelopersSecurity',
      icon: 'lock.png',
      text: 'benefitsDevelopersSecurityText',
    },
    {
      title: 'benefitsDevelopersHigh',
      icon: 'dashbord.png',
      text: 'benefitsDevelopersHighText',
    },
    {
      title: 'benefitsDevelopersUltra',
      icon: 'balance.png',
      text: 'benefitsDevelopersUltraText',
    }
  ]
};

class BenefitsList extends PureComponent {
  render() {
    const { status, intl } = this.props;

    return (
      <div className="grid-wrapper benefits__list">
        {
          slider[status].map((item, index) => (
            <div
              key={index}
              className={'benefits__list__item col-4'}
            >
              <div
                className={`benefits__list__item__icon ${item.icon.slice(0, (item.icon.length - 4))}`}
              >
                <Image
                  file={item.icon}
                />
              </div>
              <h3 className="benefits__list__item__title">{intl.formatMessage({id: item.title})}</h3>
              <p className="benefits__list__item__text">{intl.formatMessage({id: item.text})}</p>
            </div>
          ))
        }
      </div>
    );
  }
}

BenefitsList.propTypes = {
  status: PropTypes.string,
  intl: PropTypes.object,
};


export default injectIntl(BenefitsList);
