import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// import {
//   Button,
// } from 'mw-style-react';
// import cn from 'classnames';
import './Roadmap.scss';
import Image from '../Image/Image';

const roadmap = [
  // {
  //   title: 'Enigma',
  //   text: 'Data encryption',
  //   start: {month: 6, year: 2019},
  //   end: {month: 9, year: 2019},
  // },
  // {
  //   title: 'process scheme migration',
  //   text: 'Ability to download and upload batch of process schemes',
  //   start: {month: 6, year: 2019},
  //   end: {month: 10, year: 2019},
  // },
  // {
  //   title: 'DB Call',
  //   text: 'Ability to call all popular databases directly via Corezoid',
  //   start: {month: 6, year: 2019},
  //   end: {month: 11, year: 2019},
  // },
  {
    title: 'Single Account',
    text: 'Unified account for all Corezoid’s products',
    start: {month: 6, year: 2019},
    end: {month: 11, year: 2019},
  },
  {
    title: 'Prolog Call',
    text: 'Ability to call DeepMemo (Prolog) via Corezoid',
    start: {month: 6, year: 2019},
    end: {month: 12, year: 2019},
  },
  {
    title: 'Prolog Editor',
    text: 'Built-in Prolog IDE in Corezoid',
    start: {month: 7.2, year: 2019},
    end: {month: 1, year: 2020},
  },
  {
    title: 'Git Call',
    text: 'Stable JS version, new languages: GO, Java',
    start: {month: 6, year: 2019},
    end: {month: 3, year: 2020},
  },
  {
    title: 'Support of SOAP/WSDL/Swagger protocols',
    text: 'New protocols for API Call node',
    start: {month: 6, year: 2019},
    end: {month: 1, year: 2020},
  },
  {
    title: 'Environment variables',
    text: 'Ability to setup environment variables for different project stages',
    start: {month: 7, year: 2019},
    end: {month: 1, year: 2020},
  },
  {
    title: 'Mapping processes',
    text: 'Ability to visualize processes’ flow, including subprocess connection\n',
    start: {month: 7, year: 2019},
    end: {month: 1, year: 2020},
  },
  {
    title: 'Corezoid call',
    text: 'Ability to call Corezoid instances',
    start: {month: 9, year: 2019},
    end: {month: 3, year: 2020},
  },
  {
    title: 'Aliases/versioning',
    text: 'Call processes by alias, version control',
    start: {month: 9, year: 2019},
    end: {month: 3, year: 2020},
  }
];


class Roadmap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: 'managers',
    };
  }

  changeStatus(status) {
    this.setState({
      status,
    });
  }

  _lengthMonth(start, end) {
    let length = 12;
    if (start.year === end.year) {
      length = end.month - start.month;
    } else {
      length = ((end.year - start.year) * 12) + (end.month - start.month);
    }
    return length;
  }

  renderList() {
    return (
      <div className="roadmap__content__list">
        {
          roadmap.map((r, index) => {
            const year = new Date().getFullYear();
            const month = new Date().getMonth() + 1;

            if (
              r.end.year < year ||
              r.end.year === year && r.end.month <= month
            ) {
              return <div/>;
            }

            return (
              <div className="roadmap__content__item" key={index}>
                <div className="roadmap__content__item__title">{r.title}</div>
                <div className="roadmap__content__item__text">{r.text}</div>
              </div>
            );
          })
        }
      </div>
    );
  }


  renderMap() {
    const start = {};
    const end = {};
    const monthList = [
      'Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'
    ];
    const thWidth = 50;
    const thHeight = 84;
    const borderWidth = 4;
    let year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;

    roadmap.forEach(r => {
      if (r.start.year < year) {
        r.start.year = year;
        r.start.month = month;
      } else if (r.start.month < month && r.start.year === year) {
        r.start.month = month;
      }


      if (!start.year) {
        start.year = r.start.year;
      } else if (r.start.year < start.year) {
        start.year = r.start.year;
      }
      if (!start.month) {
        start.month = r.start.month;
      } else if (r.start.month < start.month) {
        start.month = r.start.month;
      }

      if (!end.year) {
        end.year = r.end.year;
      } else if (r.end.year > end.year) {
        end.year = r.end.year;
        if (r.end.month < end.month) {
          end.month = r.end.month;
        }
      }
      if (!end.month) {
        end.month = r.end.month;
      } else if (r.end.month > end.month && r.end.year === end.year) {
        end.month = r.end.month;
      }
      r.length = this._lengthMonth(r.start, r.end);
    });
    const length = this._lengthMonth(start, end);

    let renderMonth = monthList.splice(start.month - 1, length);
    let newYearMouth = [];

    if (end.year > new Date().getFullYear()) {
      newYearMouth = monthList.splice(0, end.month);
    }

    renderMonth = renderMonth.concat(newYearMouth);
    return (
      <div className="roadmap__table">
        <table>
          <tr style={{ width: `${thWidth * renderMonth}px`}}>
            {renderMonth.map((m, index) => {
              if (index > 0 && m === 'Jan') {
                year += 1;
              }
              return (
                <th
                  key={index}
                  style={{
                    minWidth: `${thWidth - borderWidth}px`,
                    height: '10px',
                    borderWidth: `${borderWidth}px`,
                    paddingBottom: '20px'
                  }}
                >
                  {m}
                  {(index === 0 || m === 'Jan') &&
                    <div className="roadmap__table__year">{year}</div>
                  }
                </th>
              );
            })}
          </tr>
          {roadmap.map((rm, index) => {
            if (rm.length < 1) return null;
            return (
              <tr key={index} style={{position: 'relative', height: '50px'}}>
                {renderMonth.map((m, indexM) => (
                  <th
                    key={indexM}
                    style={{
                      minWidth: `${thWidth - borderWidth}px`,
                      height: `${thHeight}px`,
                      borderWidth: `${borderWidth}px`
                    }}
                  />
                ))}
                <div
                  style={{
                    width: `${(thWidth + borderWidth / 2) * rm.length + borderWidth}px`,
                    height: `${thHeight - 32}px`,
                    position: 'absolute',
                    borderRadius: '8px',
                    background: '#D3E0E5',
                    left: `${(rm.start.month - start.month) * thWidth - ((rm.start.month - start.month) === 0 ? borderWidth / 2 : 0)}px`
                  }}
                >
                  <div
                    style={{
                      width: `${(thWidth + borderWidth) * 2}px`,
                      position: 'absolute',
                      right: 0,
                      background: '#57B557',
                      height: `${thHeight - 32}px`,
                      borderRadius: `${rm.length === 2 ? '8px' : '0 8px 8px 0'}`,
                    }}
                  />
                </div>
              </tr>
            );
          })}
        </table>
      </div>
    );
  }

  render() {
    // const { intl } = this.props;

    return (
      <div className="roadmap">
        <div className="roadmap__header">
          <h1 className="roadmap__header__title">
            {/* {intl.formatMessage({ id: 'roadmapTitle' })} */}
            Roadmap
          </h1>
          <Image file={'roadmap.png'} />
        </div>
        <div className="roadmap__content">
          {this.renderList()}
          {this.renderMap()}
        </div>
        <div className="roadmap__info">
          <div className="roadmap__info__item">
            <div className="roadmap__info__item__block grey"/>
            <div className="roadmap__info__item__text">Development and testing</div>
          </div>
          <div className="roadmap__info__item">
            <div className="roadmap__info__item__block green"/>
            <div className="roadmap__info__item__text">Production testing and enterprise delivery</div>
          </div>
        </div>
      </div>
    );
  }
}

Roadmap.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(Roadmap);

